import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import { TextLabel } from "../../shared/components";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.clear();
    console.error(`Ocorreu um erro na renderização`);
    console.error("Error :", error);
    console.error("ErrorInfo :", errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          style={{ height: "100vh", width: "100vw" }}
        >
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{ height: 100, width: "25%" }}
            >
              <TextLabel label="Ocorreu um erro inesperado" />
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.props.history.replace("/")}
              >
                Voltar para tela inicial
              </Button>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
