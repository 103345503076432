import _ from "lodash";
import moment from "moment";
import dayjs from "dayjs";
import UsuarioService from "../../services/UsuarioService";
import { clienteLite } from "../cliente/clienteUtils";
moment.locale("pt-BR");

let timeoutRef = null;
export function debounce(fn, delay = 800) {
  clearTimeout(timeoutRef);
  timeoutRef = setTimeout(fn, delay);
}

export const deveExibirAnalises = (state) => {
  return !clienteLite(state.cliente.ClienteSelecionado)
    && localStorage.getItem("usuario_tableau") === "true";
};

export const deveExibirAvisoConsultoria = (state) => {
  return toggleEstaAtiva(state.fazenda.Toggles, "MENSAGEM_AVISO_CONSULTORIA");
};

export const deveExibirConfiguracaoFazenda = (state) => {
  return (
    !clienteLite(state.cliente.ClienteSelecionado) &&
    toggleEstaAtiva(state.fazenda.Toggles, "CONFIGURACAO_FAZENDA")
  );
};

export const deveExibirCadastroUsuario = (state) => {
  const isInternalUser = isLoggedUserInternal();
  const usuarioClientes = state.cliente.UsuarioClientes;
  const clienteSelecionado = usuarioClientes.find((cliente) => cliente.IdCliente === state.cliente.ClienteSelecionado.IdCliente);
  const isManagerUser = clienteSelecionado && clienteSelecionado.UsuarioAdministrador;
  return isInternalUser || isManagerUser;
};

export const deveExibirFechamentoConsumo = (state) => {
  const toggles = state.fazenda.Toggles;
  const possuiToggleFechamentoProduto = toggleEstaAtiva(
    toggles,
    "FECHAMENTO_CONSUMO_PRODUTO"
  );
  return (
    !clienteLite(state.cliente.ClienteSelecionado) &&
    possuiToggleFechamentoProduto
  );
};

export const deveExibirRelatorioTableau = (state) => {
  const toggles = state.fazenda.Toggles;
  const exibeRelatorioTableau = toggleEstaAtiva(toggles, "RELATORIO_TABLEAU");
  return (
    !clienteLite(state.cliente.ClienteSelecionado) && exibeRelatorioTableau
  );
};

export const deveExibirCadastroFormulacao = (state) => {
  const toggles = state.fazenda.Toggles;
  const exibeListagemLote = toggleEstaAtiva(toggles, "COLETA_DE_FORMULACAO");
  return exibeListagemLote;
};

export const deveExibirGanhoPeso = (state) => {
  const toggles = state.fazenda.Toggles;
  const exibeGanhoPeso = toggleEstaAtiva(toggles, "CADASTRO_GANHO_PESO");
  return exibeGanhoPeso;
};

export const deveExibirCreep = (state) => {
  const toggles = state.fazenda.Toggles;
  const exibeCreep = toggleEstaAtiva(toggles, "CREEP_MANEJO");
  return exibeCreep;
};

export const deveExibirColunaPesoProjetado = (state) => {
  const toggles = state.fazenda.Toggles;
  const exibeColunaPesoProjetado = toggleEstaAtiva(
    toggles,
    "COLUNA_PESO_PROJETADO"
  );
  return exibeColunaPesoProjetado;
};

export const obtemDadosFazendaSelecionada = (state) => {
  const fazendas = _.filter(state.fazenda.Fazendas, function (fazenda) {
    return fazenda.IdFazenda === state.fazenda.FazendaSelecionada;
  });
  return fazendas;
};

export const obtemDadosClienteFazendaSelecionada = (state, IdCliente) => {
  const Clientes = _.filter(state.cliente.Clientes, function (c) {
    return (
      c.IdCliente === IdCliente &&
      c.ClienteFinanceiro === true &&
      c.IdContaFinanceiro !== 0
    );
  });
  return Clientes;
};

export const deveExibirFinanceiro = (state) => {
  const fazendas = obtemDadosFazendaSelecionada(state);
  if (!clienteLite(state.cliente.ClienteSelecionado) && fazendas.length > 0) {
    if (
      fazendas[0].IdContaFinanceiro !== null &&
      fazendas[0].IdContaFinanceiro !== 0
    ) {
      if (state.usuario.Usuario) {
        return (
          state.usuario.Usuario.IdUsuarioFinanceiro !== 0 &&
          state.usuario.Usuario.SenhaFinanceiro !== null
        );
      }
    } else {
      const Cliente = obtemDadosClienteFazendaSelecionada(
        state,
        fazendas[0].IdCliente
      );
      if (state.usuario.Usuario) {
        return (
          Cliente.length > 0 &&
          state.usuario.Usuario.IdUsuarioFinanceiro !== 0 &&
          state.usuario.Usuario.SenhaFinanceiro !== null
        );
      }
    }
  } else {
    return false;
  }
};

export const obterTokenAcessoFinanceiro = async (state) => {
  const fazendas = obtemDadosFazendaSelecionada(state);
  let request = {};
  if (
    fazendas[0].IdContaFinanceiro !== null &&
    fazendas[0].IdContaFinanceiro !== 0
  ) {
    request = {
      username: state.usuario.Usuario.Login,
      password: state.usuario.Usuario.SenhaFinanceiro,
      empresa_id: fazendas[0].IdContaFinanceiro,
    };
  } else {
    const Cliente = obtemDadosClienteFazendaSelecionada(
      state,
      fazendas[0].IdCliente
    );
    if (Cliente.length > 0) {
      request = {
        username: state.usuario.Usuario.Login,
        password: state.usuario.Usuario.SenhaFinanceiro,
        empresa_id: Cliente[0].IdContaFinanceiro,
      };
    }
  }
  try {
    const tokenObtido = await UsuarioService.obterTokenAcessoFinanceiro(
      request
    );
    return tokenObtido;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const obtemRoleUsuario = async (state) => {
  try {
    var tokenObtido = await UsuarioService.obtemRoleUsuario(state);
    if (tokenObtido.data.length < 1) {
      return null;
    }
    return tokenObtido.data[0].name;
  } catch (err) {
    return null;
  }
};

export const retornaDiferencaDatas = (dataInicio, dataFim) => {
  const inicioFormatado = moment.utc(dataInicio);
  const fimFormatado = moment.utc(dataFim);
  return fimFormatado.diff(inicioFormatado, "days");
};

const toggleEstaAtiva = (toggles, nomeToggle) => {
  return _.some(toggles, (t) => t.Nome === nomeToggle && t.Ativo);
};

export const deveExibirColunaSanidade = (state) => {
  const toggles = state.fazenda.Toggles;
  const exibeColunaSanidade = toggleEstaAtiva(toggles, "COLETA_SANIDADE");
  return exibeColunaSanidade;
};

export const deveExibirTrocaCategoria = (state) => {
  const toggles = state.fazenda.Toggles;
  const exibeTrocaCategoria = toggleEstaAtiva(toggles, "TROCA_DE_CATEGORIA");
  return exibeTrocaCategoria;
};

export const deveExibirObjetivoLote = (state) => {
  const toggles = state.fazenda.Toggles;
  const exibeObjetivoLote = toggleEstaAtiva(toggles, "CADASTRO_OBJETIVO_LOTE");
  return exibeObjetivoLote;
};

export const deveExibirHistoricoChuva = (state) => {
  const toggles = state.fazenda.Toggles;
  const exibirHistoricoChuva = toggleEstaAtiva(toggles, "CHUVA");
  return exibirHistoricoChuva;
};
export const deveExibirCausaMorte = (state) => {
  const toggles = state.fazenda.Toggles;
  const exibeCausaMorte = toggleEstaAtiva(toggles, "CADASTRO_CAUSA_MORTE");
  return exibeCausaMorte;
};
export const deveExibirBombona = (state) => {
  const toggles = state.fazenda.Toggles;
  const exibeBombona = toggleEstaAtiva(toggles, "COLETA_BOMBONA");
  return exibeBombona;
};

export const deveExibirMorteNascimento = (state) => {
  const toggles = state.fazenda.Toggles;
  const exibeMorteNascimento = toggleEstaAtiva(toggles, "MORTE_NASCIMENTO");
  return exibeMorteNascimento;
};

export const deveExibirAjusteEscore = (state) => {
  const toggles = state.fazenda.Toggles;
  const exibeAjusteEscore = toggleEstaAtiva(toggles, "SEMI_CONFINAMENTO");
  return exibeAjusteEscore;
};

export const deveExibirSemiConfinamento = (state) => {
  const toggles = state.fazenda.Toggles;
  const exibeAjusteEscore = toggleEstaAtiva(toggles, "SEMI_CONFINAMENTO");
  return exibeAjusteEscore;
};

export const deveExibirCampoIdPrime = (state) => {
  const toggles = state.fazenda.Toggles;
  const exibeCampoIdPrime = toggleEstaAtiva(
    toggles,
    "INTEGRACAO_SEMI_CONFINAMENTO_PRIME"
  );
  return exibeCampoIdPrime;
};

export const deveExibirConciliacao = (state) => {
  const toggles = state.fazenda.Toggles;
  const exibirConciliacao = toggleEstaAtiva(
    toggles,
    "OPERACOES_SOMENTE_NO_CURRAL"
  );
  return exibirConciliacao;
};

export const deveExibirBalancoArroba = (state) => {
  const toggles = state.fazenda.Toggles;
  const exibirBalancoArroba = toggleEstaAtiva(toggles, "BALANCO_ARROBA");
  return exibirBalancoArroba;
};

export const isLoggedUserInternal = () => {
  const isInternalUser = localStorage.getItem("UsuarioInterno");
  return isInternalUser ? JSON.parse(isInternalUser) : "";
};

export const meses = [
  {
    value: 1,
    label: "Janeiro",
  },
  {
    value: 2,
    label: "Fevereiro",
  },
  {
    value: 3,
    label: "Março",
  },
  {
    value: 4,
    label: "Abril",
  },
  {
    value: 5,
    label: "Maio",
  },
  {
    value: 6,
    label: "Junho",
  },
  {
    value: 7,
    label: "Julho",
  },
  {
    value: 8,
    label: "Agosto",
  },
  {
    value: 9,
    label: "Setembro",
  },
  {
    value: 10,
    label: "Outubro",
  },
  {
    value: 11,
    label: "Novembro",
  },
  {
    value: 12,
    label: "Dezembro",
  },
];
// VIEWS 659  VERIFICAR CHAMADA DESSA FUNÇÃO
export const getFazendaSelecionada = (fazendaSelecionada, Fazendas) => {
  return _.find(
    Fazendas,
    (fazenda) => fazenda.IdFazenda === fazendaSelecionada
  );
};

export const verificaExisteSubString = (
  textoVerificar = "",
  subString = ""
) => {
  const textoVerificarValido =
    typeof textoVerificar === "string" && textoVerificar.length > 0;
  const subStringValido = typeof subString === "string" && subString.length > 0;
  if (textoVerificarValido && subStringValido) {
    textoVerificar = textoVerificar.toUpperCase();
    subString = subString.toUpperCase();
    return textoVerificar.includes(subString);
  }
  return false;
};

export const abreviaNome = (nome = "") => {
  if (typeof nome != "string") return "";

  const nomeDivido = nome.split(" ");

  if (nomeDivido && nomeDivido.length > 2) {
    const primeiroNome = nomeDivido[0];
    const ultimoNome = nomeDivido[nomeDivido.length - 1];
    let nomesMeio = nomeDivido.slice(1, nomeDivido.length - 1);

    const nomesAbreviados = nomesMeio
      .map((nome) => {
        if (nome.length > 3) {
          nome = `${nome[0].toUpperCase()}.`;
        }
        return nome;
      })
      .join(" ");

    return `${primeiroNome} ${nomesAbreviados} ${ultimoNome}`;
  }
  return nome || "";
};

export const objetoPreenchido = (objetoVerificar) => {
  return !_.isEmpty(objetoVerificar);
};

export const maskDatePicker = [
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const imprimirPage = (contentInnerHTML, frame, style) => {
  frame.document.open();
  frame.document.write(contentInnerHTML);
  frame.document.head.appendChild(style);
  frame.document.close();
  frame.focus();
  frame.print();
};

export function fixInitialDateAndFinalDateTimezone(data) {
  if (!data) return data;

  if (data && Array.isArray(data)) {
    return data.map((item) => {
      return fixInitialDateAndFinalDateTimezone(item);
    });
  }

  const fixedInitialDate = dayjs(data.initialDate).add(5, "hour").toISOString();
  const fixedFinalDate = dayjs(data.finalDate).add(5, "hour").toISOString();

  return {
    ...data,
    initialDate: fixedInitialDate,
    finalDate: fixedFinalDate,
  };
}
