import { Modal, withStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import CloseICon from "@material-ui/icons/Close";
import news from "news";
import { connect } from "react-redux";
import moment from "moment";

function News({ user, farmId, classes }) {
  const [visible, setVisible] = useState(true);
  const [newMessage, setNewMessage] = useState(null);

  const getDate = (date) => {
    return moment(moment(date).format("YYYY-MM-DD") + " 00:00:00");
  };

  const today = getDate(moment());

  const doClose = () => {
    setVisible(false);
  };

  const shouldDisplayNoRepeat = (itemNew, data) => {
    return moment(itemNew.maxDate) > moment() && data.countViews === 0;
  };

  const shouldDisplayDaily = (itemNew, data) => {
    return (
      getDate(itemNew.maxDate) >= today &&
      (data.lastView === null || getDate(data.lastView) < today)
    );
  };

  const shouldDisplay = (itemNew, data) => {
    const repeat = {
      daily: shouldDisplayDaily,
      "no-repeat": shouldDisplayNoRepeat,
    };
    return repeat[itemNew.repeat](itemNew, data);
  };

  const prepare = async () => {
    if (user && user.Usuario) {
      const basicData = {
        lastView: null,
        countViews: 0,
      };

      const resultJson = localStorage.getItem("@prodap-news-registers");
      const result = JSON.parse(resultJson) || {};

      const resultsToUser = result[user.Usuario.Login]
        ? result[user.Usuario.Login]
        : {};

      for (let i = 0; i < news.length; i++) {
        const _new = news[i];
        const _newByUser = resultsToUser[_new.id]
          ? resultsToUser[_new.id]
          : basicData;

        if (shouldDisplay(_new, _newByUser)) {
          const dataToStorage = {
            ...result,
            [user.Usuario.Login]: {
              ...resultsToUser,
              [_new.id]: {
                lastView: getDate(moment()).format("YYYY-MM-DD"),
                countViews: (resultsToUser.countViews || 0) + 1,
              },
            },
          };
          localStorage.setItem(
            "@prodap-news-registers",
            JSON.stringify(dataToStorage)
          );
          setNewMessage(_new);
          setVisible(true);
          return;
        }
      }
    }
  };

  useEffect(() => {
    prepare();
  }, [user]);

  if (!user || !user.Usuario || !newMessage || !farmId) return null;

  return (
    <Modal
      open={visible}
      disableBackdropClick={true}
      disableEnforceFocus={true}
      keyboard={false}
      exibeBotaoFechar={true}
      className={classes.modalContainer}
    >
      <div className={classes.container}>
        <CloseICon className={classes.modalClose} onClick={() => doClose()} />
        <h2 className={classes.modalTitle}>
          {newMessage.title ? newMessage.title : "Temos novidades!"}
        </h2>
        {newMessage.image === false ? null : (
          <img
            src={"/images/news.png"}
            alt={newMessage.title ? newMessage.title : "Novidades!"}
            className={classes.modalImage}
          />
        )}
        <div className={classes.modalContent}>
          <span className={classes.modalText}>{newMessage.message}</span>
        </div>
        {newMessage.link && (
          <a href={newMessage.link} className={classes.modalLink}>
            Ver novidade
          </a>
        )}
        {!newMessage.link && (
          <span className={classes.modalLink} onClick={() => setVisible(false)}>
            Entendi
          </span>
        )}
      </div>
    </Modal>
  );
}
const styles = (theme) => ({
  modalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    width: "578px",
    minHeight: "533px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
    backgroundColor: "#fff",
    "&:focus-visible": {
      outline: "none",
    },
  },
  modalTitle: {
    width: "493px",
    height: "28px",
    flexGrow: "0",
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.17",
    letterSpacing: "0.44px",
    textAlign: "center",
    color: theme.palette.primary.main,
    margin: "0 0 20px",
  },
  modalClose: {
    alignSelf: "end",
    cursor: "pointer",
  },
  modalImage: {
    width: "137px",
    height: "137px",
    flexGrow: "0",
    padding: "0 1.2px",
  },
  modalLink: {
    width: "184px",
    height: "41px",
    flexGrow: "0",
    backgroundColor: theme.palette.primary.main,
    margin: "0 0px 0 0",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#fff",
    lineHeight: "41px",
    textDecoration: "none",
    borderRadius: "21px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  modalContent: {
    padding: "24px 20px",
  },
  modalText: {
    display: "block",
    fontFamily: "Roboto",
    minHeight: "115px",
    fontSize: "18px",
    lineHeight: "1.33",
    textAlign: "justify",
    color: "#666",
  },
});

const mapStateToProps = (state) => ({
  user: state.usuario,
  farmId: state.fazenda.FazendaSelecionada,
});

export default withStyles(styles)(connect(mapStateToProps)(News));
