import BaseService from "./BaseService";

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}usuarios/api/v1/usuarios/`;
const URL_BASEV2 = `${process.env.REACT_APP_ENDPOINT}usuarios/api/v2/usuarios/`;
const URL_BASE_CLIENTES = `${process.env.REACT_APP_ENDPOINT}clientes/api/v2/clientes/`;

class ClienteService extends BaseService {
  async listarClientes(idUsuario) {
    const queryParams = new URLSearchParams();

    queryParams.append("withStatus", true);

    const url = this.montaURL(URL_BASEV2 + idUsuario + "/clientes", queryParams.toString());
    const retorno = await this.get(url);
    return retorno;
  }

  async listarClientesAtivos(idUsuario) {
    const filter = this.montaFiltro({
      where: {
        IdUsuario: idUsuario,
        $or: [{ Ativo: true }, { UsuarioAdministrador: true }],
      },
    });

    const url = this.montaURL(URL_BASE + idUsuario + "/clientes", filter);
    const retorno = await this.get(url);
    return retorno;
  }

  async listarTodosClientes() {
    const retorno = await this.get(URL_BASE_CLIENTES);
    return retorno;
  }

  async listarDadosClientes(idsClientes) {
    const queryParams = new URLSearchParams();

    for (const idCliente of idsClientes) {
      queryParams.append("IdCliente", idCliente);
    }

    const url = this.montaURL(`${URL_BASE_CLIENTES}`, queryParams.toString());

    const dadosClientes = await this.get(url);
    return dadosClientes.data;
  }
}

export default new ClienteService();
