export const TipoCliente = {
  ClienteLite: 1,
  ClienteSolteiro: 2,
  ClienteSTP: 3,
};

export const EventoCliente = {
  SUSPENSAO: "SUSPENSAO",
  ACESSO_RESTAURADO: "ACESSO_RESTAURADO",
  ATIVACAO: "ATIVACAO",
  INATIVACAO: "INATIVACAO",
  CRIACAO: "CRIACAO",
};
