import React, { useState } from "react";
import { Divider, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import * as fazendaActions from "features/manejo/redux/actions/fazendaActions";
import BotaoUpload from "features/shared/components/BotaoUpload";
import _ from "lodash";
import { connect } from "react-redux";
import { exportPastureCSV, saveCSV } from "services/PastureCsv";
import * as actions from "redux/actions";
import { exibirCarregando } from "redux/actions/loadingAction";
import * as notificaoActions from "redux/actions/notificacaoActions";
import LeitorArquivo from "lib/LeitorArquivo";
import Auth from "../../../../authentication/Auth";
import KMLFazenda from "../UploadKml/KMLFazenda";
import MenuLateralKML from "../UploadKml/MenuLateralKML";

function PastureOptions({
  // states
  classes,
  history,
  fazendaSelecionada,
  kmlFarmInfo,
  pastos,
  disable,
  // actions
  notifyInfo,
  setCoordenadasFazenda,
  carregarCoordPastos,
  notifySuccess,
  setCadastrandoKml,
  exibirCarregando,
  notifyError,
  setFazendaInfo,
  notifyWarning,
  listaPastos,
}) {
  const [leitorArquivo] = useState(new LeitorArquivo());
  const [kmlHandler] = useState(new KMLFazenda());
  const [tipoUpload, setTipoUpload] = useState("");

  const newPastureClick = () => {
    const path = pastos.length
      ? "/cadastro_novo_pasto"
      : "/cadastro_nova_fazenda";
    history.push(path);
  };

  const routeChangeRetiro = () => {
    const path = "/cadastro_retiro";
    history.push(path);
  };

  const onEditClick = () => {
    history.replace("cadastro_editar_pasto");
  };

  const onInputFileOpen = (tipoUpload, extensao) => {
    leitorArquivo.setExtensaoArquivo(extensao);
    setTipoUpload(tipoUpload);
  };

  const updateFarmCoordenates = ({ Retiros }) => {
    const pastoReferencia = kmlHandler.getPastoReferencia(Retiros, notifyInfo);
    if (!_.isEmpty(pastoReferencia)) {
      setCoordenadasFazenda(pastoReferencia.Centro);
      carregarCoordPastos(kmlHandler.getPastosCoordenadas()); // salvar pastos no redux (inclusive pastos dentro de modulos)
      notifySuccess({
        mensagem: "Upload feito com sucesso",
        variant: "success",
      });
      setCadastrandoKml(true);
      kmlHandler.resetCoordenadasPastos();
    }
  };

  const trataBinarioArquivo = async (binarioArquivo) => {
    try {
      const IdFazenda = fazendaSelecionada;

      if (binarioArquivo) {
        if (tipoUpload === "kmlPasto") {
          await saveKMLFile(IdFazenda, binarioArquivo);
        } else if (tipoUpload === "csvPasto") {
          await saveCSVFile(IdFazenda, binarioArquivo);
        }
      }
    } catch (error) {
      exibirCarregando(false);
      notifyError({
        mensagem:
          error.name === "nomeRepetido"
            ? error.message
            : "KML fora do padrão. Corrija seu mapa e tente novamente.",
      });
    }
  };

  const saveKMLFile = async (IdFazenda, binarioArquivo) => {
    const fazendaInfo = kmlHandler.montaEstruturaParaSalvar(
      binarioArquivo,
      IdFazenda
    );
    if (!fazendaInfo.erro) {
      const pastoRepetido = [];
      fazendaInfo.Retiros.forEach((retiro) => {
        pastoRepetido.push(
          retiro.Pastos.filter((pasto) => {
            const pastosRepetidos = retiro.Pastos.filter(
              (p) => p.Nome === pasto.Nome
            );
            return pastosRepetidos.length > 1;
          })
        );
      });
      if (!pastoRepetido.some((p) => p.length > 0)) {
        updateFarmCoordenates(fazendaInfo);
        setFazendaInfo(fazendaInfo);
      } else {
        const errorMessage = {
          name: "nomeRepetido",
          message: "Há pasto com nomes repetidos dentro do mesmo retiro.",
        };
        throw errorMessage;
      }
    }
  };

  const saveCSVFile = async (IdFazenda, binarioArquivo) => {
    exibirCarregando(true);

    const salvaCSV = await saveCSV(
      binarioArquivo.replace(/ï»¿/g, ""),
      IdFazenda
    );

    exibirCarregando(false);

    if (salvaCSV.erro) {
      notifyError({
        mensagem: salvaCSV.erro,
      });
    } else {
      if (salvaCSV.warning) {
        notifyWarning({
          mensagem: salvaCSV.warning,
        });
      } else {
        notifySuccess({
          mensagem: "Pastos alterados com sucesso",
        });
      }
      listaPastos(IdFazenda);
    }
  };

  const onDrop = (files) => {
    leitorArquivo.lerArquivo(files, (resultado) => {
      if (resultado.concluido) {
        trataBinarioArquivo(resultado.fileAsBinaryString);
      } else {
        notifyInfo({
          mensagem: resultado.motivo,
        });
      }
    });
  };

  const validaInputArquivo = (accepted, rejected) => {
    const respostaInputArquivo = leitorArquivo.validaInputArquivo(
      accepted,
      rejected
    );
    if (respostaInputArquivo.entradaArquivoValido) {
      onDrop(accepted);
    } else {
      notifyInfo({
        mensagem: respostaInputArquivo.mensagem,
      });
    }
  };

  const exportarCSVCamposPastoCocho = () => {
    exportPastureCSV(fazendaSelecionada);
  };

  const importCsvConfirmMessage = (className) => (
    <div className={className}>
      <p>
        <b>Atenção!</b> Os campos:{" "}
        <b>
          Nome retiro, nome módulo, área aberta, nome forragem, compartilhado e
          IdCocho"
        </b>
        , não podem ser editados. Por esse motivo esses campos serão ignorados
        ao importar.
      </p>
      <p>
        Em casos de cochos compartilhados todas as linhas correspondentes ao
        cocho devem ser editadas igualmente.
      </p>
    </div>
  );

  const { permissions } = Auth.getSession();

  const {
    containerInfo,
    containerBotao,
    dialogConfirm,
    buttonLabel,
    button,
    divider,
    subtitle,
  } = classes; // from material ui

  if (kmlFarmInfo) {
    return <MenuLateralKML />;
  }
  return (
    <div data-testid="containerCadastroPasto">
      <div className={containerInfo}>
        <Typography variant="subtitle2" className={subtitle}>
          Selecione abaixo a opção que deseja.
        </Typography>
      </div>

      {pastos.length > 0 && (
        <div className={containerBotao}>
          <span className={buttonLabel}>Editar pastos cadastrados</span>
          <Button
            className={button}
            variant="contained"
            color="primary"
            type="button"
            onClick={onEditClick}
          >
            Editar Pasto
          </Button>
        </div>
      )}
      <div className={containerBotao}>
        <span className={buttonLabel}>Cadastrar novo pasto da fazenda</span>
        <Button
          className={button}
          variant="contained"
          color="primary"
          type="button"
          onClick={() => newPastureClick()}
        >
          Novo Pasto
        </Button>
      </div>
      <div className={containerBotao}>
        <span className={buttonLabel}>Cadastrar novo retiro da fazenda</span>
        <Button
          className={button}
          variant="contained"
          color="primary"
          type="button"
          onClick={() => routeChangeRetiro()}
        >
          Novo Retiro
        </Button>
      </div>
      {(permissions.importar_mapas ||
        permissions.exportar_importar_csv_pasto === "true") && (
          <Divider variant="middle" className={divider} />
        )}
      {permissions.importar_mapas && (
        <div data-testid="containerImportacaoMapa">
          <div className={containerBotao}>
            <span className={buttonLabel}>
              Importar arquivo KML com o mapa da fazenda
            </span>
            <BotaoUpload
              onInputFileOpen={onInputFileOpen}
              extensaoArquivoAceito=".kml"
              tipoUpload="kmlPasto"
              onDrop={validaInputArquivo}
              disabled={disable}
              tituloBotao="Importar mapa"
              cunstomClasses={containerBotao}
              buttomCustomClasses={button}
            />
          </div>
        </div>
      )}
      {permissions.exportar_importar_csv_pasto && (
        <div data-testid="containerPastoCSV">
          <div className={containerBotao}>
            <span className={buttonLabel}>
              Importar CSV para edição dos pastos cadastrados
            </span>
            <BotaoUpload
              onInputFileOpen={onInputFileOpen}
              extensaoArquivoAceito=".csv"
              tipoUpload="csvPasto"
              onDrop={validaInputArquivo}
              disabled={disable}
              tituloBotao="Importar CSV"
              confirmBefore={true}
              confirmTitle="Importar CSV"
              confirmMessage={importCsvConfirmMessage(dialogConfirm)}
              confirmButtonText="Entendi"
              cunstomClasses={containerBotao}
              buttomCustomClasses={button}
            />
          </div>
          <div className={containerBotao}>
            <span className={buttonLabel}>
              Exporta o CSV com listagem dos pastos cadastrados
            </span>
            <Button
              className={button}
              variant="contained"
              color="primary"
              type="button"
              onClick={() => exportarCSVCamposPastoCocho()}
            >
              Exportar CSV
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  containerInfo: {
    margin: "32px 0px 16px 0px",
  },
  subtitle: {
    fontSize: "16px",
    fontFamily: "Roboto",
    letterSpacing: "normal",
  },
  buttonLabel: {
    fontFamily: "Roboto",
    color: "rgba(33, 33, 33, 0.7)",
    marginTop: 16,
    marginBottom: 8,
  },
  button: {
    textTransform: "none",
    fontSize: "16px",
    borderRadius: 4,
    width: "100%",
  },
  containerBotao: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    margin: 0,
  },
  dialogConfirm: {
    color: "#333333",
  },
  divider: {
    margin: "32px 0px 16px 0px",
  },
};

function mapStateToProps({ fazenda, pasto, kml }) {
  return {
    fazendaSelecionada: fazenda.FazendaSelecionada,
    pastos: pasto.Pastos,
    kmlFarmInfo: kml.fazendaInfo,
  };
}

const mapDispatchToProps = {
  listaPastos: actions.listaPastos,
  habilitarDesenhar: actions.habilitarDesenhar,
  exibirCarregando,
  ...notificaoActions,
  ...fazendaActions,
  ...actions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PastureOptions));
