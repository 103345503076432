import history from "../app/History";
import MetricsService, { EventType } from "services/MetricsService";

export const getMenuItems = (
  exibirAnalise,
  exibirFinanceiro,
  exibirFechamentoConsumo,
  renderFuncionalidadesManejo,
  exibirConfiguracaoFazenda,
  fazendaSelecionada,
  exibirRelatorioTableau,
  setModalTableau
) => {
  const navegaParaFechementoConsumo = () => {
    if (!window.location.href.includes("fechamento_consumo")) {
      MetricsService.sendEvent({
        event: EventType.ACESSO_FECHAMENTO_CONSUMO,
        route: window.location.href,
      });
    }
    history.push("/fechamento_consumo");
  };

  const menuItems = [
    {
      label: "Análises",
      id: "analyzes",
      showItem: exibirAnalise,
      icon: "insert_chart",
      navigateTo: () => history.push("/analytics"),
    },
    {
      label: "Financeiro",
      id: "financial",
      icon: "attach_money",
      showItem: exibirFinanceiro,
    },
    {
      label: "Fechamento de consumo",
      id: "closing-consumption",
      icon: "straighten",
      showItem: exibirFechamentoConsumo,
      navigateTo: navegaParaFechementoConsumo,
    },
    {
      label: "Cadastros da fazenda",
      id: "farm-records",
      icon: "settings",
      showItem: renderFuncionalidadesManejo && exibirConfiguracaoFazenda,
      navigateTo: () =>
        history.push("/configuracoes") || window.location.reload(),
    },
    {
      label: "Coletas do aplicativo",
      id: "app-collects",
      icon: "mobile_screen_share_outlined",
      showItem: fazendaSelecionada,
      navigateTo: () => history.push("/coletas") || window.location.reload(),
    },
    {
      label: "Gerador de relatórios",
      id: "reports-generator",
      icon: "assignment",
      showItem: exibirRelatorioTableau,
      navigateTo: () => setModalTableau(true),
    },
    {
      label: "Controle de Rebanho",
      id: "herd-control",
      icon: "device_hub",
      showItem: true,
      navigateTo: () => history.push("/controleDeRebanho"),
    },
    {
      label: "Movimentações de Rebanho",
      id: "herd-moves",
      icon: "find_replace",
      showItem: true,
      navigateTo: () => history.push("/historico_movimentacao_aplicativo"),
    },
  ];

  menuItems.sort((a, b) =>
    a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase() ? 1 : -1
  );

  return [
    {
      label: "Início",
      id: "home",
      icon: "home",
      showItem: true,
      navigateTo: () => redirecionaHistoryManual({ novaRota: "/farol" }),
    },
    ...menuItems,
  ];
};

const validacaoRotas = (rota) => window.location.pathname.includes(rota);

/*
Função para criar manualmente o history,
validar a rota atual, se necessário,
e redirecionar para a pagina desejada
*/
const redirecionaHistoryManual = ({ rota = "", novaRota }) => {
  if (validacaoRotas(rota) && !!novaRota) {
    history.push(novaRota);
    history.go(0);
  }
};

const changeLocation = (rota) => {
  window.location = rota;
};

export { redirecionaHistoryManual, changeLocation };
