import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { OverlayView } from "@react-google-maps/api";
import Tooltip from "../Tooltip";
import CalculadoraCoordenadas from "../../../../../lib/calculadoraCoordenadas";
import Poligono from "../Poligono";
import { coresModulos } from "../../../../../constants/coresModulos";
import shortid from "shortid";
class Pastos extends Component {
  state = {
    mouseOver: false,
  };

  onMouseOverChange = (over) => {
    this.setState({ ...this.state, mouseOver: over });
  };

  render() {
    return (
      <div>
        {this.renderPoligono()}
        {this.renderInfo()}
        {this.renderNumerosPastosSelecionados()}
      </div>
    );
  }

  renderPoligono = () => {
    const { Pasto, selecionado, bloqueado, corSelecionada, clicavel } =
      this.props;
    return (
      <Poligono
        onClick={(e) => this.props.onClick(e)}
        coordenadas={Pasto.Coordenadas}
        cor={coresModulos[corSelecionada]}
        onMouseOverChange={(over) => this.onMouseOverChange(over)}
        highlight={this.state.mouseOver || selecionado}
        block={bloqueado}
        raised={clicavel && !bloqueado}
      />
    );
  };

  renderInfo = () => {
    if (this.props.selecionado === false && this.state.mouseOver) {
      const position = CalculadoraCoordenadas.calcularCentroAlto(
        this.props.Pasto.Coordenadas
      );

      let text = this.props.Pasto.NomeReduzido
        ? this.props.Pasto.NomeReduzido
        : this.props.Pasto.Nome;

      if (this.props.avisoPastoSemRetiro) {
        text += "\n\n" + this.props.avisoPastoSemRetiro;
      }

      return (
        <Tooltip
          Posicao={position}
          Texto={text}
        />
      );
    } else {
      return <div />;
    }
  };

  renderNumerosPastosSelecionados = () => {
    const { selecionado, zoomMapa, Pasto, ordem } = this.props;
    if (zoomMapa && zoomMapa < 13) return <React.Fragment />;
    if (selecionado) {
      const tamanhoFonte = obtemTamanhoFonte(zoomMapa);
      return (
        <React.Fragment>
          <OverlayView
            key={shortid.generate()}
            position={CalculadoraCoordenadas.calcularCentro(Pasto.Coordenadas)}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={(width, height) => ({
              x: -(width / 2),
              y: -(height / 2),
            })}
          >
            <span style={{ color: "#FFF", fontSize: tamanhoFonte }}>
              {ordem}
            </span>
          </OverlayView>
        </React.Fragment>
      );
    }
  };
}

const obtemTamanhoFonte = (zoomMapa) => {
  if (zoomMapa === 13) return "1em";
  else if (zoomMapa === 14) return "1.5em";
  else return "2em";
};

Pastos.propTypes = {
  Pasto: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  zoomMapa: PropTypes.number,
  selecionado: PropTypes.bool.isRequired,
  bloqueado: PropTypes.bool.isRequired,
  corSelecionada: PropTypes.string.isRequired,
};

export default connect(null, null)(Pastos);
