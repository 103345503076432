import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "../NewLoginPage/styles";

export function PageLayout({ classes, children }) {
  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <div className={classes.logoWrapper}>
          <a href="/">
            <img
              className={classes.logoWrapperImg}
              src="https://storage.googleapis.com/armazenamento-imagens/logo/logo-Farmtell-Views-TM-Cor.svg"
              alt=""
            />
          </a>
        </div>
      </header>
      <main className={classes.page}>{children}</main>
    </div>
  );
}

export default withStyles(styles)(PageLayout);
