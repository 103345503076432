import * as _ from "lodash";
import calculaCentro from "../../../../../lib/calculaCentroPoligono";
import elementtree from "elementtree";
import simplify from "@turf/simplify";

export default class KMLFazenda {
  constructor() {
    this.pastosCarregarCoordenadas = [];
  }

  getPastoReferencia = (Retiros) => {
    if (Retiros.length) {
      const primeiroRetiro = Retiros[0];
      let pastoReferencia = {};

      if (!_.isEmpty(primeiroRetiro.Pastos)) {
        pastoReferencia = primeiroRetiro.Pastos[0];
      } else if (primeiroRetiro.Modulos.length) {
        const primeiroModulo = primeiroRetiro.Modulos[0];
        if (!_.isEmpty(primeiroModulo.Pastos)) {
          pastoReferencia = primeiroModulo.Pastos[0];
        }
      } else {
        console.error("O arquivo KML carregado não possui pastos.");
      }
      return pastoReferencia;
    }
  };

  simplifyCoords = (coords) => {
    try {
      const makeGeoJson = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [coords.map((coord) => [coord.lng, coord.lat])],
            },
            properties: {
              name: "MAKE",
            },
            id: "make-geo-json",
          },
        ],
      };

      const options = { tolerance: 0.0001, highQuality: true };
      const simplified = simplify(makeGeoJson, options);
      const simplifiedCoords = simplified.features[0].geometry.coordinates[0];
      return simplifiedCoords.map((coord) => ({
        lng: coord[0],
        lat: coord[1],
      }));
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  criarPastos = (pastosKml, nomeRetiro, nomeModulo) => {
    if (!pastosKml) return [];

    return pastosKml
      .map((pastoKml) => this.criarPasto(pastoKml, nomeRetiro, nomeModulo))
      .filter((pasto) => pasto);
  };

  criarPasto = (pastoKml, nomeRetiro, nomeModulo = "") => {
    const ehPoligonoValido = this.validaSePastoEhPoligonoValido(pastoKml);
    if (!ehPoligonoValido) return;

    const coordenadasArray = pastoKml
      .find(".//Polygon//coordinates")
      .text.trim();
    const pastoNaoTemCoordenadas =
      !coordenadasArray || coordenadasArray.length === 0;
    if (pastoNaoTemCoordenadas) return;

    const nomePasto = pastoKml.find("./name").text;
    const coordenadas = [];
    const coordenadasArrayReplace = coordenadasArray
      .replace(/\s\s+/g, " ")
      .split(/[\s\n]/);

    for (let index = 0; index < coordenadasArrayReplace.length; index++) {
      const coordenada = coordenadasArrayReplace[index].split(",");
      const lng = Number(coordenada[0]);
      const lat = Number(coordenada[1]);
      if (typeof lng === "number" && typeof lat === "number") {
        coordenadas.push({ lng, lat });
      }
    }

    // não simplificar poligos pequenos para preservar sua forma
    const simpleCoords =
      coordenadas.length > 25 ? this.simplifyCoords(coordenadas) : coordenadas;

    const pastoTratado = {
      Nome: nomePasto,
      nomeRetiro,
      nomeModulo,
      Centro: calculaCentro(simpleCoords ? simpleCoords : coordenadas),
      Coordenadas: simpleCoords ? simpleCoords : coordenadas,
    };

    this.pastosCarregarCoordenadas.push(pastoTratado);
    return pastoTratado;
  };

  validaSePastoEhPoligonoValido = (pastoKml) => {
    const polygons = pastoKml.findall(".//Polygon");
    if (polygons || polygons.length === 1) return true;

    return false;
  };

  mapeiaPastosRetiro = (pastosKml, nomeRetiro) => {
    return this.criarPastos(pastosKml, nomeRetiro);
  };

  criarModulo = (moduloKml, nomeRetiro) => {
    const nomeModulo = moduloKml.find("./name").text;

    const pastosKml = moduloKml.findall("./Placemark");
    const pastos = this.criarPastos(pastosKml, nomeRetiro, nomeModulo);

    return { Nome: nomeModulo, Pastos: pastos, nomeRetiro };
  };

  mapeiaModulosPorRetiro = (modulosKml, nomeRetiro) => {
    if (!modulosKml.length) return [];

    return modulosKml
      .map((modulo) => this.criarModulo(modulo, nomeRetiro))
      .filter((modulo) => modulo.Pastos.length > 0);
  };

  mapeiaRetirosPorFazenda = (retirosKml) => {
    if (!retirosKml.length) return [];

    return retirosKml
      .map((retiro) => {
        const nomeRetiro = retiro.find("./name").text;

        const modulosKml = retiro.findall("./Folder");
        const modulos = this.mapeiaModulosPorRetiro(modulosKml, nomeRetiro);

        const pastosKml = retiro.findall("./Placemark");
        const pastos = this.mapeiaPastosRetiro(pastosKml, nomeRetiro);

        return { Nome: nomeRetiro, Modulos: modulos, Pastos: pastos };
      })
      .filter((retiro) => retiro.Modulos.length || retiro.Pastos.length);
  };

  montaEstruturaParaSalvar = (fileAsBinaryString, IdFazenda) => {
    const { nomeFazenda, retirosKml } =
      this.obtemNomeFazendaERetiros(fileAsBinaryString);
    const retiros = this.mapeiaRetirosPorFazenda(retirosKml);

    return {
      IdFazenda: IdFazenda,
      Nome: nomeFazenda,
      Retiros: retiros,
    };
  };

  obtemNomeFazendaERetiros = (fileAsBinaryString) => {
    const kmlTree = elementtree.parse(fileAsBinaryString);
    const fazendaKml = kmlTree.find("./Document");
    const nomeFazenda = fazendaKml.find("./name").text;
    const retirosKml = fazendaKml.findall("./Folder");

    return {
      nomeFazenda,
      retirosKml,
    };
  };

  getPastosCoordenadas = () => {
    return this.pastosCarregarCoordenadas;
  };

  resetCoordenadasPastos = () => {
    this.pastosCarregarCoordenadas = [];
  };
}
