import { Button, withStyles, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import supplementationService from "../../supplementationService";
import SupplementationFormCard from "./components/supplementationFormCard";
import * as actions from "redux/actions";
import { connect } from "react-redux";
import AlertIcon from "@material-ui/icons/WarningTwoTone";
import moment from "moment";
import PageTitle from "../shared/pageTitle";

const SupplementationEditionContainer = ({
  notifySuccess,
  notifyError,
  history,
  loading,
  classes,
  match,
}) => {
  const { params } = match;
  const [formIsValid, setFormIsValid] = useState(true);
  const [values, setValues] = useState({});

  const submit = async () => {
    try {
      loading(true);

      let amountTroughBags = values.amountTroughBags;
      if (amountTroughBags !== null && amountTroughBags !== undefined) {
        amountTroughBags = amountTroughBags.toString();
        amountTroughBags = Number(amountTroughBags.replace(",", "."));
      }
      values.amountTroughBags = amountTroughBags;
      const result = await supplementationService.updateSupplementation(
        params.supplementationId,
        values
      );
      if (result) {
        notifySuccess({
          mensagem: "Coleta salva com sucesso",
        });
        history.push(`/coletas/suplementacao/${params.supplementationId}`);
      } else {
        throw new Error("Erro ao enviar a solicitação");
      }
    } catch (error) {
      console.error(error);
      notifyError(error);
    } finally {
      loading(false);
    }
  };

  const onGoBack = () => {
    history.push(`/coletas/suplementacao/`);
  };

  return (
    <div className={classes.container}>
      <PageTitle title={"Editar Coleta"} onGoBack={onGoBack} />
      <div className={classes.txtAlertContainer}>
        <AlertIcon className={classes.iconAlert} />
        <span className={classes.txtAlert}>
          Atenção! A edição realizada repercutirá no Fechamento de Consumo e
          Módulo Analítico apenas no próximo dia{" "}
          {moment().add(1, "days").format("DD/MM/YYYY")}.
        </span>
      </div>
      <SupplementationFormCard
        supplementationId={params.supplementationId}
        onFormValidation={setFormIsValid}
        onChangeValues={setValues}
      />
      <div className={classes.buttonBox}>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={
            formIsValid
              ? "Por favor, preencha todos os campos para salvar a coleta"
              : ""
          }
          arrow
          placement="left"
        >
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              variant="contained"
              className={classes.buttonSave}
              disabled={formIsValid}
              onClick={submit}
            >
              Salvar Edição
            </Button>
          </div>
        </Tooltip>

        <div className={classes.buttonContainer}>
          <Button
            className={classes.buttonCancel}
            variant="outlined"
            color="primary"
            onClick={history.goBack}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: "65px 40px 40px",
    background: "#fff",
  },
  buttonBox: {
    marginTop: "134px",
    display: "flex",
    flexDirection: "row-reverse",
  },
  buttonContainer: {
    width: "157px",
    marginLeft: "16px",
  },
  buttonCancel: {
    width: "100%",
  },
  buttonSave: {
    width: "100%",
  },
  tooltip: {
    width: "210px",
    height: "76px",
    padding: "16px",
    borderRadius: "4px",
    backgroundColor: "#616161",
    fontFamily: "Roboto",
    fontSize: "14px",
  },
  iconAlert: {
    color: "#f44336",
  },
  txtAlertContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
    marginTop: "33px",
  },
  txtAlert: {
    margin: "0 0 0 8px",
    fontFamily: "Roboto",
    fontSize: "16px",
    lineHeight: "1.44",
    letterSpacing: "0.2px",
    textAlign: "left",
    color: "#333",
  },
};

const mapDispatchToProps = {
  notifySuccess: actions.notifySuccess,
  notifyError: actions.notifyError,
  loading: actions.exibirCarregandoSalvando,
};

const mapStateToProps = ({ fazenda }) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SupplementationEditionContainer));
