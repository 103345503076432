import React from "react";
import Auth from "features/authentication/Auth";
import ConfirmationModal from "features/shared/components/confirmationModal";
import { WarningAmberOutlined } from "@mui/icons-material";



const ModalLockClient = ({
  classes,
}) => {
  return (
    <ConfirmationModal
      submitButtonClass={classes.submitButtonClass}
      customClass={classes.customClass}
      submitButtonContainer={classes.submitButtonContainer}
      customTitleClass={classes.modalTitleLeft}
      title={
        <span className={classes.modalTitle} style={{
          display: "flex",
          alignItems: "center",
          gap: 8,
          fontSize: 24
        }}>
          <WarningAmberOutlined />
          Atenção!
        </span>
      }
      text={
        <span className={classes.modalText}>
          Houve um problema com seu acesso. Por favor, entre em contato com o suporte para mais detalhes.
        </span>
      }
      submitButtonTitle="FECHAR"
      onSubmit={Auth.logout}
      onClose={Auth.logout}
    />
  );
}

export default ModalLockClient;
