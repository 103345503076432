import { notifyError } from "../../../redux/actions/notificacaoActions";
import HistoricoMovimentacaoManager from "../historicoMovimentacaoManager";
import {
  listarHistoricoMovimentacaoFazenda,
  exibirCarregando,
  listarFiltrosHistoricoMovimentacao,
  salvarFiltrosSelecionados,
  exibirModal,
  listarHistoricoMovimentacaoFazendaSemPaginacao,
} from "./historicoMovimentacaoActions";

export const obterHistoricoMovimentacaoFazenda =
  (page, limit, filtro, semPaginacao = false) =>
  async (dispatch, getState) => {
    const offset = page + 1;

    dispatch(exibirCarregando(true));
    try {
      const { fazenda } = getState();
      if (semPaginacao) {
        const historicoMovimentacaoSemPaginacao =
          await HistoricoMovimentacaoManager.obterHistoricoMovimentacaoFazenda(
            fazenda.FazendaSelecionada,
            0,
            999,
            filtro
          );
        dispatch(
          listarHistoricoMovimentacaoFazendaSemPaginacao(
            historicoMovimentacaoSemPaginacao
          )
        );
      } else {
        const historicoMovimentacao =
          await HistoricoMovimentacaoManager.obterHistoricoMovimentacaoFazenda(
            fazenda.FazendaSelecionada,
            offset,
            limit,
            filtro
          );
        const exibir = historicoMovimentacao.total === 0;
        dispatch(exibirModal(exibir));
        dispatch(listarHistoricoMovimentacaoFazenda(historicoMovimentacao));
      }
    } catch (error) {
      const historico = {
        items: [],
        total: 0,
        limit: 0,
        offset: 0,
      };
      dispatch(listarHistoricoMovimentacaoFazenda(historico));
      dispatch(
        notifyError({
          mensagem:
            "Ocorreu um erro ao obter a lista de histórico movimentação.",
        })
      );
    } finally {
      dispatch(exibirCarregando(false));
    }
  };

export const obterFiltrosHistoricoMovimentacao =
  () => async (dispatch, getState) => {
    dispatch(exibirCarregando(true));
    try {
      const { fazenda } = getState();
      const filtrosHistoricoMovimentacao =
        await HistoricoMovimentacaoManager.obterFiltrosHistoricoMovimentacao(
          fazenda.FazendaSelecionada
        );

      dispatch(
        listarFiltrosHistoricoMovimentacao(filtrosHistoricoMovimentacao)
      );
    } catch (error) {
      dispatch(listarFiltrosHistoricoMovimentacao([]));
      dispatch(
        notifyError({
          mensagem: "Ocorreu um erro ao obter a lista de filtros.",
        })
      );
    } finally {
      dispatch(exibirCarregando(false));
    }
  };

export const atualizaFiltroSelecionado = (filtro) => (dispatch) => {
  dispatch(salvarFiltrosSelecionados(filtro));
};

export const alterarEstadoModal = (estado) => (dispatch) => {
  dispatch(exibirModal(!estado));
};
