import cadastroIngredientesService from "../cadastroIngredientesService";
import {
  notifyError,
  notifySuccess,
} from "../../../redux/actions/notificacaoActions";
import {
  listarIngredientes,
  listarIngredientesPadroes,
  exibirCarregando,
  listarHistoricoIngredientes,
} from "./cadastroIngredientesActions";
import { ordenaIngredientesPadroes } from "../cadastroIngredientesUtils";

function extractErrorMessage(
  error,
  defaultMessage,
  { concatAllErrors = false, separator = ", " } = {}
) {
  let message = defaultMessage;

  if (!error) {
    return message;
  }

  const {
    response: {
      data: { errors },
    },
  } = error;

  if (errors && errors.length) {
    if (typeof errors[0] === "string" || errors[0] instanceof String) {
      message = concatAllErrors
        ? errors.map((e) => e).join(separator)
        : errors[0];
    } else {
      message = concatAllErrors
        ? errors.map((e) => e.mensagem).join(separator)
        : errors[0].mensagem;
    }
  }

  return message;
}

export const obterTodasOperacoesIngrediente =
  () => async (dispatch, getState) => {
    dispatch(exibirCarregando(true));
    try {
      const { fazenda } = getState();
      const ingredientes =
        await cadastroIngredientesService.obterTodasOperacoesIngrediente(
          fazenda.FazendaSelecionada
        );
      if (ingredientes.data.items) {
        ingredientes.data.items.forEach((ingrediente) => {
          ingrediente.productName = ingrediente.Produto
            ? `${ingrediente.Produto.name} ${ingrediente.Produto.code}`
            : "";
        });
      }

      dispatch(listarIngredientes(ingredientes.data));
    } catch (error) {
      dispatch(
        notifyError({
          mensagem: extractErrorMessage(
            error,
            "Ocorreu um erro ao obter a lista de ingredientes."
          ),
        })
      );
    } finally {
      dispatch(exibirCarregando(false));
    }
  };

export const filtrarOperacoesIngrediente =
  (filtro) => async (dispatch, getState) => {
    dispatch(exibirCarregando(true));
    try {
      const { fazenda } = getState();
      const ingredientes =
        await cadastroIngredientesService.filtrarOperacoesIngredientes(
          fazenda.FazendaSelecionada,
          filtro
        );
      dispatch(listarIngredientes(ingredientes.data));
    } catch (error) {
      dispatch(
        notifyError({
          mensagem: extractErrorMessage(
            error,
            "Ocorreu um erro ao obter a lista de ingredientes."
          ),
        })
      );
    } finally {
      dispatch(exibirCarregando(false));
    }
  };
export const obterTodasMateriasPrimas = () => async (dispatch) => {
  dispatch(exibirCarregando(true));
  try {
    const ingredientePadroes =
      await cadastroIngredientesService.obterTodasMateriasPrimas();

    const ingredientesPadroesOrdenados = ingredientePadroes.data;

    ingredientesPadroesOrdenados.items = ordenaIngredientesPadroes(
      ingredientesPadroesOrdenados.items
    );

    dispatch(listarIngredientesPadroes(ingredientesPadroesOrdenados));
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: extractErrorMessage(
          error,
          "Ocorreu um erro ao obter a lista de ingredientes padrões."
        ),
      })
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};

// função criada com conceito de mockup precisa de modificações para ser implementadaa
export const criarIngrediente = (ingrediente) => async (dispatch) => {
  dispatch(exibirCarregando(true));
  try {
    await cadastroIngredientesService.criarIngrediente(ingrediente);
    dispatch(obterTodasOperacoesIngrediente());
    dispatch(
      notifySuccess({
        mensagem: "Ingrediente criado com sucesso.",
      })
    );
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: extractErrorMessage(
          error,
          "Ocorreu um erro ao salvar ingredientes."
        ),
      })
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};

// função criada com conceito de mockup precisa de modificações para ser implementadaa
export const editarIngrediente =
  (ingrediente) => async (dispatch, getState) => {
    dispatch(exibirCarregando(true));
    try {
      const { fazenda } = getState();
      ingrediente.IdFazenda = fazenda.FazendaSelecionada;
      await cadastroIngredientesService.editarIngrediente(
        ingrediente.IdIngrediente,
        ingrediente
      );
      dispatch(obterTodasOperacoesIngrediente());
      dispatch(
        notifySuccess({
          mensagem: "Ingrediente editado com sucesso.",
        })
      );
    } catch (error) {
      dispatch(
        notifyError({
          mensagem: extractErrorMessage(
            error,
            "Ocorreu um erro ao editar ingrediente."
          ),
        })
      );
    } finally {
      dispatch(exibirCarregando(false));
    }
  };

export const desativaIngrediente =
  (IdIngrediente, nomeUsuario, selectedState) => async (dispatch) => {
    dispatch(exibirCarregando(true));
    try {
      await cadastroIngredientesService.desativaIngrediente(
        IdIngrediente,
        nomeUsuario
      );
      dispatch(obterTodasOperacoesIngrediente());
      dispatch(
        notifySuccess({
          mensagem: "Ingrediente desativado com sucesso!",
        })
      );
      dispatch(
        filtrarOperacoesIngrediente(
          selectedState === 0 ? {} : { Ativo: selectedState === 1 }
        )
      );
    } catch (error) {
      dispatch(
        notifyError({
          mensagem: extractErrorMessage(
            error,
            "Ocorreu um erro ao desativar ingrediente."
          ),
        })
      );
    } finally {
      dispatch(exibirCarregando(false));
    }
  };

export const ativaIngrediente =
  (IdIngrediente, nomeUsuario, selectedState) => async (dispatch) => {
    dispatch(exibirCarregando(true));
    try {
      await cadastroIngredientesService.ativaIngrediente(
        IdIngrediente,
        nomeUsuario
      );
      dispatch(
        filtrarOperacoesIngrediente(
          selectedState === 0 ? {} : { Ativo: selectedState === 1 }
        )
      );
    } catch (error) {
      dispatch(
        notifyError({
          mensagem: extractErrorMessage(
            error,
            "Ocorreu um erro ao ativar ingrediente."
          ),
        })
      );
    } finally {
      dispatch(exibirCarregando(false));
    }
  };

export const obterHistorico = (IdIngrediente) => async (dispatch) => {
  try {
    const historico = await cadastroIngredientesService.obterHistorico(
      IdIngrediente
    );
    dispatch(listarHistoricoIngredientes(historico.data));
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: extractErrorMessage(
          error,
          "Ocorreu um erro ao abrir o histórico."
        ),
      })
    );
  }
};
