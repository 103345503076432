import React, { useState, useEffect } from "react";
import history from "../../../app/History";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core";
import * as actions from "redux/actions";
import ClienteService from "services/ClienteService";
import FazendaService from "services/FazendaService";
import PastoService from "services/PastoService";
import ModalNoClient from "./ModalNoClient";
import ModalNoFarmOnClient from "./ModalNoFarmOnClient";
import ModalNoFarmOnUser from "./ModalNoFarmOnUser";
import ModalPastureRegistration from "./ModalPastureRegistration";
import { EventoCliente } from "features/cliente/clienteConstants";
import ModalLockClient from "./ModalLockClient";

const MODALS = {
  LOCK_CLIENT: "LOCK_CLIENT",
  NO_CLIENT: "NO_CLIENT",
  NO_FARM_ON_USER: "NO_FARM_ON_USER",
  NO_FARM_ON_CLIENT: "NO_FARM_ON_CLIENT",
  NO_PASTURE: "NO_PASTURE",
}
//  TODO : MELHORAR IMPLEMENTAÇAO APOS RESOLUÇAO DO DEBITO TÉCNICO - MASTER - xxxx
let timeOut = null;

const isSuspendedOrInactive = (clientList, internUser) => {
  return clientList.length === 1 && [EventoCliente.SUSPENSAO, EventoCliente.INATIVACAO].includes(clientList[0].StatusEvento) && !internUser;
}

const UserClientFarmValidation = ({
  notifyError,
  classes,
  clienteSelecionado,
  fazendaSelecionada,
}) => {
  const [whichModalShouldBeRender, setWhichModalShouldBeRender] =
    useState(null);

  const getClients = async () => {
    try {
      const userId = localStorage.getItem("IdUsuario");
      const clientList = await ClienteService.listarClientes(userId);
      return clientList.data;
    } catch (error) {
      notifyError({
        mensagem: error.message,
      });
    }
  };

  const getFarmsByUser = async () => {
    if (!clienteSelecionado) return [];
    try {
      const userId = localStorage.getItem("IdUsuario");
      const farmList = await FazendaService.listarFazendasUsuario(userId);
      return farmList.data;
    } catch (error) {
      notifyError({
        mensagem: error.message,
      });
    }
  };

  const getFarmsByClient = async () => {
    if (!clienteSelecionado) return [];
    try {
      const userId = localStorage.getItem("IdUsuario");
      const farmList = await FazendaService.listarFazendasCliente(
        userId,
        clienteSelecionado.IdCliente
      );
      return farmList.data;
    } catch (error) {
      notifyError({
        mensagem: error.message,
      });
    }
  };

  const getPastures = async () => {
    if (!fazendaSelecionada) return [];
    try {
      const pastureList = await PastoService.listaPastosPorIdFazenda(
        fazendaSelecionada
      );
      return pastureList.data;
    } catch (error) {
      notifyError({
        mensagem: error.message,
      });
    }
  };

  useEffect(() => {
    if (timeOut) {
      clearTimeout(timeOut)
    }
    timeOut = setTimeout(checkUserLink, 1000)
  }, [clienteSelecionado, fazendaSelecionada]);

  const checkUserLink = async () => {
    const clientList = await getClients();
    if (clientList.length === 0) {
      setWhichModalShouldBeRender(MODALS.NO_CLIENT);
      return;
    }

    let internUserStored = localStorage.getItem("UsuarioInterno");
    internUserStored = internUserStored ? internUserStored : "false";
    const internUser = JSON.parse(internUserStored);
    if (isSuspendedOrInactive(clientList, internUser)) {
      setWhichModalShouldBeRender(MODALS.LOCK_CLIENT);
      return;
    }

    const farmListByUser = await getFarmsByUser();

    if (clientList.length && clienteSelecionado) {
      if (farmListByUser.length) {
        setWhichModalShouldBeRender(null);
      } else {
        setWhichModalShouldBeRender(MODALS.NO_FARM_ON_USER);
        return;
      }
    }

    const farmListByClient = await getFarmsByClient();

    if (clientList.length && clienteSelecionado && farmListByClient.length === 0) {
      setWhichModalShouldBeRender(MODALS.NO_FARM_ON_CLIENT);
      return;
    }

    const isOnCadastroPage = history.location.pathname.includes("cadastro");

    if (isOnCadastroPage) return;

    const pasturesFromFarm = await getPastures();

    if (fazendaSelecionada) {
      if (pasturesFromFarm.length) {
        setWhichModalShouldBeRender(null);
        return;
      } else {
        setWhichModalShouldBeRender(MODALS.NO_PASTURE);
      }
    }

  };

  const closeModals = () => {
    setWhichModalShouldBeRender(null)
  }


  if (whichModalShouldBeRender === MODALS.LOCK_CLIENT)
    return <ModalLockClient classes={classes} />;

  if (whichModalShouldBeRender === MODALS.NO_CLIENT)
    return <ModalNoClient classes={classes} />;

  if (whichModalShouldBeRender === MODALS.NO_FARM_ON_USER)
    return <ModalNoFarmOnUser classes={classes} />;

  if (whichModalShouldBeRender === MODALS.NO_FARM_ON_CLIENT)
    return (
      <ModalNoFarmOnClient
        classes={classes}
        setIsModalShown={closeModals}
        clienteSelecionado={clienteSelecionado}
      />
    );

  if (whichModalShouldBeRender === MODALS.NO_PASTURE)
    return (
      <ModalPastureRegistration
        classes={classes}
        setIsModalShown={closeModals}
      />
    );

  return null;
};

const styles = (theme) => ({
  modalText: {
    color: "#666666",
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: "normal",
    lineHeight: "1.44",
  },
  modalTextInfo: {
    color: theme.palette.primary.main,
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: "500",
  },
  modalTitle: {
    color: "#eb394b",
    fontFamily: "Roboto",
    fontSize: "22px",
    fontWeight: "bold",
  },
  modalTitleLeft: {
    alignSelf: "flex-start",
  },
  submitButtonContainer: {
    width: "258px",
    height: "40px",
    flexGrow: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 16px",
    borderRadius: "100px",
    backgroundColor: theme.palette.primary.main,
    marginTop: 38,
    marginBottom: 30,
  },
  customClass: {
    textAlign: "justify",
    alignItems: "center",
    maxWidth: "471px",
    display: "flex",
    flexDirection: "column",
    padding: "50px 32px 0",
    borderRadius: "8px",
    backgroundColor: "#fff",
  },
  submitButtonClass: {
    width: "150px",
    height: "40px",
    borderRadius: "100px",
    textTransform: "none",
  },
  cancelButtonClass: {
    width: "130px",
    height: "40px",
    borderRadius: "100px",
    textTransform: "none",
    backgroundColor: "#fff",
  },
  buttonsContainer: {
    textTransform: "none",
    marginTop: 13,
    marginBottom: 32,
  },
});

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  notifyError: actions.notifyError,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(UserClientFarmValidation);
