import novatentativa from "../../lib/novatentativa";
import BaseService from "../../services/BaseService";

export const URL_BASE = `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/retiros`;
class RetiroService extends BaseService {
  /**
   * Busca os nomes dos retiros de uma fazenda pelo IdFazenda
   * @param {number} IdFazenda
   * @param {Array} camposRetornar campos a retornar, opcional
   */
  async listaRetirosPorIdFazenda(IdFazenda, camposRetornar = []) {
    try {
      const tempFilter = {
        where: {
          IdFazenda: Array.isArray(IdFazenda) ? IdFazenda : [IdFazenda],
        },
      };

      if (camposRetornar.length) {
        tempFilter.attributes = camposRetornar;
      }

      const filtro = this.montaURLParametros({
        filter: JSON.stringify(tempFilter),
      });

      const urlServico = this.montaURL(URL_BASE, filtro);
      const response = await this.get(urlServico);
      return response;
    } catch (erro) {
      return {
        erro,
      };
    }
  }

  async listaRetirosPorIdsFazenda(idsFazenda) {
    try {
      const filtro = { where: { IdFazenda: { $in: idsFazenda } } };
      const parametrosURL = this.montaFiltro(filtro);
      const urlServico = this.montaURL(URL_BASE, parametrosURL);
      const resultado = await this.get(urlServico);
      return resultado.data;
    } catch (error) {
      return {
        error,
      };
    }
  }

  /**
   * Salva multiplos retiros na atualização do KML
   * @param {Array} retirosSalvar
   */
  async salvaMultiplosRetirosFazenda(retirosSalvar) {
    try {
      if (retirosSalvar.length) {
        const tentativa = new novatentativa(2);
        const retirosInseridos = await Promise.all(
          retirosSalvar.map((retiro) =>
            tentativa.postServicoUrl(URL_BASE, retiro)
          )
        );
        return retirosInseridos;
      } else {
        return;
      }
    } catch (error) {
      console.error("error :", error);
    }
  }

  async criarUsuarioRetiro(idUsuario, usuarioRetiros) {
    const URL = `${process.env.REACT_APP_ENDPOINT}usuarios/api/v1/usuarios/${idUsuario}/retiros`;
    const usuariosRetirosInseridos = await Promise.all(
      usuarioRetiros.map((retiro) => {
        const body = {
          IdFazenda: retiro.data.IdFazenda,
          IdRetiro: retiro.data.IdRetiro,
        };
        return this.post(URL, body);
      })
    );

    return usuariosRetirosInseridos;
  }

  async excluirRetiro(idRetiro) {
    return await this.delete(`${URL_BASE}/${idRetiro}`);
  }

  async atualizarRetiro(id, retiro) {
    return await this.put(`${URL_BASE}/${id}`, retiro);
  }
}

export default new RetiroService();
