import { useCallback } from "react";
import { useCookies } from "react-cookie";

const getUserIdFromLocalStorage = () =>
  window.localStorage.getItem("IdUsuario");

const NAME_COOKIE = "ftvnp";
const NAME_COOKIE_NOTIFICATION = "ftvnvl";

const useCookNotification = () => {
  const [cookies, setCookie] = useCookies([
    NAME_COOKIE,
    NAME_COOKIE_NOTIFICATION,
  ]);

  const getPendencie = useCallback(
    (farmId) => {
      const userId = getUserIdFromLocalStorage();
      const ftvnpCookie = cookies[NAME_COOKIE] ? cookies[NAME_COOKIE] : [];

      if (!Array.isArray(ftvnpCookie)) {
        return null;
      }

      const item = ftvnpCookie.find((item) =>
        item.includes(`${userId}|${farmId}|`)
      );
      if (item) {
        return item.split("|")[2];
      }
      return;
    },
    [cookies]
  );

  const setPendencie = useCallback(
    (farmId, data) => {
      const userId = getUserIdFromLocalStorage();
      if (
        userId === null ||
        farmId === null ||
        userId === undefined ||
        farmId === undefined
      )
        return;

      const cookiesToSave = cookies[NAME_COOKIE] ? cookies[NAME_COOKIE] : [];

      if (!Array.isArray(cookiesToSave)) {
        setCookie(NAME_COOKIE, [`${userId}|${farmId}|${data}`]);
        return;
      }

      const index = cookiesToSave.findIndex((item) =>
        item.includes(`${userId}|${farmId}|`)
      );

      if (index !== -1) cookiesToSave[index] = `${userId}|${farmId}|${data}`;
      else cookiesToSave.push(`${userId}|${farmId}|${data}`);

      setCookie(NAME_COOKIE, cookiesToSave);
    },
    [cookies]
  );

  const setNotificationView = useCallback(
    (notificationId) => {
      const userId = getUserIdFromLocalStorage();
      if (userId === null || userId === undefined) return;

      const cookiesToSave = cookies[NAME_COOKIE_NOTIFICATION]
        ? cookies[NAME_COOKIE_NOTIFICATION]
        : [];

      if (!Array.isArray(cookiesToSave)) {
        setCookie(NAME_COOKIE_NOTIFICATION, [`${userId}|${notificationId}|`]);
        return;
      }

      const index = cookiesToSave.findIndex((item) =>
        item.includes(`${userId}|${notificationId}|`)
      );

      if (index !== -1) return;
      else cookiesToSave.push(`${userId}|${notificationId}|`);

      setCookie(NAME_COOKIE_NOTIFICATION, cookiesToSave);
    },
    [cookies]
  );

  const getNotificationsViewed = useCallback(() => {
    const userId = getUserIdFromLocalStorage();
    const ftvnlCookie = cookies[NAME_COOKIE_NOTIFICATION]
      ? cookies[NAME_COOKIE_NOTIFICATION]
      : [];

    if (!Array.isArray(ftvnlCookie)) {
      return null;
    }

    const viewedNotifications = ftvnlCookie
      .filter((item) => item.includes(userId))
      .map((item) => item.split("|")[1]);

    return viewedNotifications;
  }, [cookies]);

  return [
    getPendencie,
    setPendencie,
    setNotificationView,
    getNotificationsViewed,
  ];
};

export default useCookNotification;
