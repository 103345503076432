import {
  Badge,
  Button,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  withStyles,
} from "@material-ui/core";
import { NotificationsNone } from "@material-ui/icons";
import React, { useRef, useState } from "react";

const isNotUndefinedOrNull = (value) => value !== undefined && value !== null;

const Notifier = ({ classes, itens = [], badgeCount }) => {
  const anchorEl = useRef(null);
  const [open, setOpen] = useState(false);
  const [arrow, setArrow] = useState(null);

  const count = isNotUndefinedOrNull(badgeCount) ? badgeCount : itens.length;

  const handleClick = () => {
    setOpen((state) => !state);
  };

  const handleClose = (event) => {
    if (anchorEl.current.contains(event.target)) return;

    setOpen(false);
  };

  const handleActionItem = (event, item) => {
    if (item.action) {
      item.action();
    }

    handleClose(event);
  };

  return (
    <div>
      <Button
        buttonRef={anchorEl}
        aria-owns={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Badge badgeContent={count} classes={{ badge: classes.counter }}>
          <NotificationsNone className={classes.icon} />
        </Badge>
      </Button>
      <Popper
        className={classes.popper}
        modifiers={{
          arrow: {
            enabled: true,
            element: arrow,
          },
        }}
        open={open}
        anchorEl={anchorEl.current}
        transition
        disablePortal
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <>
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "left top" : "right bottom",
              }}
            >
              <>
                <span
                  className={classes.arrow}
                  ref={(node) => setArrow(node)}
                />
                <Paper elevation={24}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                      {itens.map((item, index) => (
                        <MenuItem
                          key={`${item.id}-${index}`}
                          onClick={(event) => handleActionItem(event, item)}
                          className={classes.menuItem}
                          onLoad={(event) => console.log("onLoad")}
                        >
                          {item.actionOnShow && item.actionOnShow()}
                          <div className={classes.menuItemTitle}>
                            {item.icon ? (
                              <ListItemIcon>{item.icon}</ListItemIcon>
                            ) : null}
                            {item.title}
                          </div>
                          {item.description ? (
                            <span className={classes.menuItemDescription}>
                              {item.description}
                            </span>
                          ) : null}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </>
            </Grow>
          </>
        )}
      </Popper>
    </div>
  );
};

const styles = (theme) => ({
  icon: {
    fill: "#5F6368",
  },
  counter: {
    backgroundColor: "#EB5757",
    color: "#fff",
  },
  popper: {
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent white transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `white transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent white transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent white`,
      },
    },
  },
  arrow: {
    zIndex: 10,
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  menuItem: {
    flexDirection: "column",
    alignItems: "flex-start",
    height: "auto",
    maxWidth: 250,
  },
  menuItemTitle: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  menuItemDescription: {
    marginTop: 8,
    textWrap: "auto",
    color: "#757575",
  },
});

export default withStyles(styles)(Notifier);
