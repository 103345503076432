import React, { Component } from "react";
import { compose } from "recompose";
import {
  Paper,
  withStyles,
  InputAdornment,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Button,
} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import FormatadorNumericoCustomizado from "features/manejo/views/components/FormatadorNumericoCustomizado";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { enviaDadosGAPorRotulo } from "../../../../../../shared/utils.js";
import { verificaToggleCreep } from "../../Farol/FarolUtils";
import { montaEstadoCocho } from "./cadastroUtils";
import SelectDropdown from "components/SelectDropdown/index.jsx";
import CochoService from "services/CochoService.js";
export class FormTrough extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Cocho: montaEstadoCocho(props.Cocho),
      CochoCreep: montaEstadoCocho(props.CochoCreep),
      FormCochoCreep: props.CochoCreep,
      expanded: props.CochoCreep,
    };
  }

  componentDidMount = () => {
    this.enviaDadosAnaliseGA();
  };

  enviaDadosAnaliseGA = () => {
    const acao = "Cadastrar pasto";
    let rotulo = "Acesso à aba avançado";
    const categoria = "Mapa";
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
  };

  onChangeCochoTipo = (value) => {
    const event = {
      target: {
        value,
      },
    };
    this.props.handleChangeFormCocho("Coberto", event);
  };

  onChangeCochoElevado = (value) => {
    const event = {
      target: {
        value,
      },
    };
    this.props.handleChangeFormCocho("Elevado", event);
  };

  onChangeCochoEstoqueFinal = (value) => {
    const event = {
      target: {
        value,
      },
    };
    this.props.handleChangeFormCocho("EstoqueFinal", event);
  };

  onChangeCochoCreepTipo = (value) => {
    const event = {
      target: {
        value,
      },
    };

    this.props.handleChangeFormCochoCreep("Coberto", event);
  };

  onChangeCochoCreepElevado = (value) => {
    const event = {
      target: {
        value,
      },
    };
    this.props.handleChangeFormCochoCreep("Elevado", event);
  };

  onChangeCochoCreepEstoqueFinal = (value) => {
    const event = {
      target: {
        value,
      },
    };
    this.props.handleChangeFormCochoCreep("EstoqueFinal", event);
  };

  handleDeleteCochoCreep = async () => {
    const { CochoCreep, exibirCarregando, notifyError } = this.props;
    try {
      if (CochoCreep && CochoCreep.IdCocho) {
        exibirCarregando(true);
        await CochoService.excluirCochoCreep(CochoCreep.IdCocho);
        this.props.handleClearCochoCreepForm();
      }
    } catch (e) {
      notifyError({
        mensagem: "Erro ao excluir cocho creep",
        variant: "error",
      });
    } finally {
      exibirCarregando(false);
    }
  };

  render() {
    const { classes, Toggles, Cocho, CochoCreep } = this.props;
    const { expanded } = this.state;
    const { Coberto, Elevado, EstoqueFinal } = this.props.listas;
    const erros = this.props.errosValidacao.Cocho;
    let cadastroCompleto = false;
    const cochoForm = montaEstadoCocho(Cocho);
    const cochoCreepForm = montaEstadoCocho(CochoCreep);

    ["Coberto", "Elevado", "EstoqueFinal"].forEach((el) => {
      const value = cochoForm[el];
      cadastroCompleto =
        cadastroCompleto &&
        value !== "" &&
        value !== null &&
        value !== undefined;
    });

    const SharedTroughWarning = () => (
      <Grid container>
        <Typography variant={"body1"} align={"left"}>
          Este cocho é compartilhado com mais de um pasto.
        </Typography>
        <Typography variant={"subtitle2"} align={"left"}>
          Para visualizar os dados, clique no botão Cadastrar Pastos {">"}
          Gestão de Vínculos {">"} Cocho Compartilhado.
        </Typography>
      </Grid>
    );

    return (
      <>
        {cochoForm.CochoCompartilhado ? (
          <SharedTroughWarning />
        ) : (
          <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid id="formCochoComponent">
              <Grid item xs={12} className={classes.defaultTroughForm}>
                <Paper className={classes.paper}>
                  <div className={classes.formContainer}>
                    <SelectDropdown
                      testId="cocho-coberto"
                      label={{ text: "Tipo" }}
                      error={!!erros.Coberto}
                      options={Coberto}
                      value={cochoForm.Coberto}
                      onChange={this.onChangeCochoTipo}
                    />
                    <TextField
                      id="comprimento"
                      variant="outlined"
                      className={classes.textField}
                      error={!!erros.Comprimento}
                      helperText={erros.Comprimento}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        inputComponent: FormatadorNumericoCustomizado,
                        endAdornment: (
                          <InputAdornment position="end">cm</InputAdornment>
                        ),
                      }}
                      label="Comprimento"
                      onChange={(event) =>
                        this.props.handleChangeFormCocho("Comprimento", event)
                      }
                      value={cochoForm.Comprimento ? cochoForm.Comprimento : ""}
                    />
                    <SelectDropdown
                      id="cocho-elevado"
                      label={{ text: "Elevado" }}
                      options={Elevado}
                      value={cochoForm.Elevado}
                      error={!!erros.Elevado}
                      helperText={erros.Elevado}
                      onChange={this.onChangeCochoElevado}
                    />
                    <SelectDropdown
                      id="cocho-estoque-final"
                      label={{ text: "Estoque Final" }}
                      options={EstoqueFinal}
                      value={cochoForm.EstoqueFinal}
                      error={!!erros.EstoqueFinal}
                      helperText={erros.EstoqueFinal}
                      onChange={this.onChangeCochoEstoqueFinal}
                    />
                  </div>
                </Paper>
              </Grid>

              {verificaToggleCreep(Toggles) && (
                <Grid item xs={12} className={classes.expansionPanelContainer}>
                  <ExpansionPanel
                    classes={{ root: classes.paper }}
                    id="formCochoCreepComponent"
                    expanded={expanded}
                  >
                    <ExpansionPanelSummary
                      id="expansionPanelSummary"
                      classes={{
                        root: classes.expansionPanelSummary,
                        expandIcon: classes.expandIcon,
                        expanded: classes.expansionPanelSummaryExpanded,
                      }}
                      expandIcon={<ExpandMoreIcon />}
                      onClick={() =>
                        this.setState({
                          expanded: !expanded,
                          FormCochoCreep: !this.state.FormCochoCreep,
                        })
                      }
                    >
                      <Typography
                        variant="subtitle2"
                        className={classes.subtitle}
                      >
                        Adicionar cocho creep
                      </Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails
                      className={classes.expansionPanelDetails}
                    >
                      <div className={classes.formContainer}>
                        <SelectDropdown
                          id="cocho-coberto-creep"
                          label={{ text: "Tipo" }}
                          options={Coberto}
                          value={cochoCreepForm.Coberto}
                          error={!!erros.Coberto}
                          helperText={erros.Coberto}
                          onChange={this.onChangeCochoCreepTipo}
                        />
                        <TextField
                          id="comprimento-creep"
                          className={classes.textField}
                          error={!!erros.Comprimento}
                          helperText={erros.Comprimento}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            inputComponent: FormatadorNumericoCustomizado,
                            endAdornment: (
                              <InputAdornment position="end">cm</InputAdornment>
                            ),
                          }}
                          label="Comprimento"
                          onChange={(event) =>
                            this.props.handleChangeFormCochoCreep(
                              "Comprimento",
                              event
                            )
                          }
                          value={
                            cochoCreepForm.Comprimento
                              ? cochoCreepForm.Comprimento
                              : ""
                          }
                        />
                        <SelectDropdown
                          id="cocho-elevado-creep"
                          label={{ text: "Elevado" }}
                          options={Elevado}
                          value={cochoCreepForm.Elevado}
                          error={!!erros.Elevado}
                          helperText={erros.Elevado}
                          onChange={this.onChangeCochoCreepElevado}
                        />
                        <SelectDropdown
                          id="cocho-estoque-final-creep"
                          label={{ text: "Estoque Final" }}
                          options={EstoqueFinal}
                          value={cochoCreepForm.EstoqueFinal}
                          error={!!erros.EstoqueFinal}
                          helperText={erros.EstoqueFinal}
                          onChange={this.onChangeCochoCreepEstoqueFinal}
                        />

                        {CochoCreep && CochoCreep.IdCocho && (
                          <Button
                            id="botaoExcluir"
                            variant="outlined"
                            className={classes.botaoExcluir}
                            onClick={this.handleDeleteCochoCreep}
                          >
                            DELETAR COCHO CREEP
                          </Button>
                        )}
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              )}
            </Grid>
          </form>
        )}
      </>
    );
  }
}

FormTrough.propTypes = {
  Cocho: PropTypes.object,
  CochoCreep: PropTypes.object,
  errosValidacao: PropTypes.object,
  handleChangeFormCocho: PropTypes.func.isRequired,
  handleChangeFormCochoCreep: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

FormTrough.defaultProps = {
  errosValidacao: {
    Coberto: "",
    Comprimento: "",
    Elevado: "",
    EstoqueFinal: "",
  },
  listas: {
    Coberto: [
      {
        emptyKey: true,
        value: "",
        disabled: true,
        label: "Selecione",
      },
      {
        value: true,
        label: "Coberto",
      },
      {
        value: false,
        label: "Descoberto",
      },
    ],
    Elevado: [
      {
        emptyKey: true,
        value: "",
        disabled: true,
        label: "Selecione",
      },
      {
        value: true,
        label: "Sim",
      },
      {
        value: false,
        label: "Não",
      },
    ],
    EstoqueFinal: [
      {
        emptyKey: true,
        value: "",
        disabled: true,
        label: "Selecione",
      },
      {
        value: true,
        label: "Sim",
      },
      {
        value: false,
        label: "Não",
      },
    ],
  },
};

const styles = (theme) => ({
  defaultTroughForm: {
    marginTop: 16,
  },
  paper: {
    padding: 16,
    borderRadius: "4px !important",
  },
  expansionPanelContainer: {
    marginTop: 40,
  },
  expansionPanelSummary: {
    padding: "0 !important",
    margin: "0 !important",
    minHeight: "0 !important",
  },
  expansionPanelSummaryExpanded: {
    padding: "0 !important",
    margin: "0 !important",
    minHeight: "0 !important",
  },
  expandIcon: {
    rigth: 0,
    padding: 0,
  },
  expansionPanelDetails: {
    padding: 0,
    marginTop: 32,
  },
  subtitle: {
    fontSize: "16px",
    fontFamily: "Roboto",
    letterSpacing: "normal",
  },
  textField: {
    width: "100%",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "100%",
  },
  botaoExcluir: {
    color: "#ff0202",
    padding: "7px 22px",
    height: 36,
    border: "solid 1px #ff0202",
    width: "fit-content",
    backgroundColor: "#ffffff",
    boxShadow: "none",
    alignSelf: "flex-end",
  },
});

export default compose(withStyles(styles))(FormTrough);
