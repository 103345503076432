import TipoMovimentacaoService from "./tipoMovimentacaoService";

export class TipoMovimentacaoManager {
  async obterTipoMovimentacao() {
    try {
      const respostaService =
        await TipoMovimentacaoService.listarTipoMovimentacao();
      const result = respostaService.data;
      return result;
    } catch (error) {
      console.error(error);
    }
  }
}

export default new TipoMovimentacaoManager();
