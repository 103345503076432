import _ from "lodash";
import React, { Component } from "react";
import { Button, Chip, FormLabel, Grid, Typography } from "@material-ui/core";
import Cancel from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import { Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import { getAnimalsQuantity } from "features/manejo/views/PasturesUtils";
import { SingleClickButton } from "features/shared/components/SingleClickButton";
import { enviaDadosGAPorRotulo } from "shared/utils";
import { cores } from "../../../../../../../constants/coresModulos";
import RadioCor from "../../RadioCor";
import { DialogExcluir } from "../DialogExcluir";
import { MensagemSemPasto } from "../MensagemSemPasto";
import { CustomTooltip } from "../CochoCompartilhado/CadastroCochoCompartilhado";

export class CadastroModulo extends Component {
  componentDidMount = () => {
    const { getPasturesQuantity } = this.props;
    getPasturesQuantity();

    const acao = "Gestão de vínculos";
    const rotulo = "Módulo";
    const categoria = "Mapa";
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
  };

  calculateAnimalsQuantity = () => {
    const { pastosSelecionados, pasturesQuantity } = this.props;
    const animalsQuantity = getAnimalsQuantity(
      pastosSelecionados,
      pasturesQuantity
    );

    return animalsQuantity;
  };
  render() {
    const {
      classes,
      pastos,
      pastosSelecionados,
      telaEdicao,
      nomeModulo,
      onChangeNome,
      nomeReduzido,
      onChangeNomeReduzido,
      corProps,
      onChangeCor,
      handleAbreModalExclusao,
      modalConfirmacaoExclusao,
      handleFechaModalExclusao,
      handleDelete,
      handleUpdate,
      desabilitaBotaoAtualizar,
      handleLimparSelecao,
      handleSubmit,
      handleRemovePasture,
    } = this.props;

    if (pastos.length === 0 || pastosSelecionados.length === 0) {
      const mensagemUsuario = {
        semPastos: "Para começar cadastre o KML da fazenda selecionada.",
        nadaSelecionado:
          "Para começar, selecione mais de um pasto ou módulo no mapa ao lado.",
      };
      return (
        <MensagemSemPasto
          pastos={pastos}
          pastosSelecionados={pastosSelecionados}
          mensagemUsuario={mensagemUsuario}
          imagemFundo={"../images/map_indicator.svg"}
        />
      );
    }

    const animalsQuantity = this.calculateAnimalsQuantity();

    return (
      <div id="menuModulo" className={classes.abaContainer}>
        <Grid container direction="row" spacing={12}>
          <Grid item xs={12}>
            <TextField
              id="name"
              label="Nome"
              className={classes.textField}
              value={nomeModulo}
              onChange={onChangeNome}
              margin="normal"
              placeholder="adicione"
              inputProps={{
                maxLength: 50,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="shortName"
              label="Nome Reduzido"
              className={classes.textField}
              value={nomeReduzido}
              onChange={onChangeNomeReduzido}
              margin="normal"
              placeholder="adicione"
              inputProps={{
                maxLength: 15,
              }}
              variant="outlined"
            />
          </Grid>
        </Grid>

        {pastosSelecionados.length > 0 && (
          <>
            <div className={classes.labels}>
              <FormLabel
                gutterBottom
                style={{ color: "#666666", fontWeight: "500" }}
              >
                Pastos selecionados:
              </FormLabel>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "normal",
                  color: "#8a8a8a",
                  margin: "4px 0 0",
                }}
              >
                Selecione dois ou mais pastos.
              </p>
              <div className={classes.selectedPastures}>
                {pastosSelecionados.map((pasture) => (
                  <Stack direction="row" spacing={1}>
                    <Chip
                      label={pasture.Nome}
                      onDelete={() => handleRemovePasture(pasture)}
                      style={{
                        backgroundColor: "#e7f1fd",
                        fontWeight: "bold",
                        margin: "5px 5px 0px 0px",
                        color: "#0053a0",
                      }}
                      deleteIcon={
                        <Cancel
                          style={{ fontSize: "14px", color: "#616161" }}
                        />
                      }
                    />
                  </Stack>
                ))}
              </div>
            </div>
            <FormLabel
              gutterBottom
              className={classes.labels}
              style={{ color: "#666666", fontWeight: "500" }}
            >
              Quantidade de animais nos pastos selecionados:
            </FormLabel>
            <Typography
              gutterBottom
              className={classes.labels}
              color="primary"
              style={{ color: "#0053a0", fontWeight: "bold" }}
            >
              {animalsQuantity}
              <span style={{ fontWeight: "normal" }}>
                {animalsQuantity === 1 ? " animal" : " animais"}
              </span>
            </Typography>
          </>
        )}

        <FormLabel
          className={classes.labels}
          component="legend"
          align="left"
          style={{ color: "#666666", fontWeight: "500" }}
        >
          Escolher cor:
        </FormLabel>

        <Grid container justify="space-between">
          {_.map(cores, (cor, index) => (
            <RadioCor
              key={index}
              corSelecionada={corProps}
              corRadio={cor}
              style={classes[`iconCor${index + 1}`]}
              handleChange={onChangeCor}
            />
          ))}
        </Grid>
        {telaEdicao ? (
          <Grid
            id="conteudoMenuEdicao"
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-start"
          >
            <Button
              id="botaoExcluirMenuEdicao"
              className={classes.buttonDelete}
              onClick={handleAbreModalExclusao}
              color="default"
              variant="contained"
              aling="rigth"
            >
              <ClearIcon className={classes.leftIcon} />
              EXCLUIR
            </Button>
            <DialogExcluir
              texto={"Excluir o Módulo selecionado?"}
              modalConfirmacaoExclusao={modalConfirmacaoExclusao}
              handleFechaModalExclusao={handleFechaModalExclusao}
              handleDelete={handleDelete}
            />
            <Button
              id="botaoAtualizarMenuEdicao"
              style={{ marginTop: "30px" }}
              onClick={handleUpdate}
              variant="contained"
              color="primary"
              aling="rigth"
              disabled={desabilitaBotaoAtualizar || !nomeModulo}
            >
              ATUALIZAR
            </Button>
          </Grid>
        ) : (
          <Grid
            id="conteudoMenuCriacao"
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-start"
          >
            <Button
              id="botaoLimparMenuCriacao"
              className={classes.buttonDelete}
              onClick={handleLimparSelecao}
              color="default"
              variant="contained"
              aling="rigth"
            >
              <ClearIcon className={classes.leftIcon} />
              LIMPAR
            </Button>

            <CustomTooltip
              title="Preencha todos os campos e selecione pelo menos dois pastos."
              placement="top"
            >
              <div style={{ marginTop: "30px" }}>
                <SingleClickButton
                  id="botaoSalvarMenuCriacao"
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  type="submit"
                  aling="rigth"
                  disabled={
                    this.props.pastosSelecionados.length < 2 ||
                    !this.props.nomeModulo ||
                    this.props.nomeModulo === ""
                  }
                >
                  SALVAR
                </SingleClickButton>
              </div>
            </CustomTooltip>
          </Grid>
        )}
      </div>
    );
  }
}

export default CadastroModulo;
