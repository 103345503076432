import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import * as action from "../../../redux/actions";
import { useLogin } from "../hooks/useLogin";

export const LoginPage = ({ classes, exibirCarregando, notifyError }) => {
  const {
    passwordVisibility,
    error,
    handleShowPassword,
    navigateToForgotPassword,
    onSubmit,
  } = useLogin({ exibirCarregando, notifyError });

  return (
    <div className={classes.container}>
      <div className={classes.loaderWrapper}>
        <div className={classes.loader}></div>
      </div>
      <div className={classes.loginPage}>
        <div className={classes.loginForm}>
          <header>
            <div className={classes.logoWrapper}>
              <img
                className={classes.logoWrapperImg}
                src="https://storage.googleapis.com/armazenamento-imagens/logo/logo-Farmtell-Views-TM-Cor.svg"
                alt=""
              />
            </div>

            <div className={classes.greetings}>
              <h1 className={classes.greetingWelcome}>Seja bem-vindo!</h1>
              <p className={classes.greetingTitle}>Faça seu login</p>
            </div>
          </header>

          <form className={classes.form} onSubmit={onSubmit}>
            <div
              className={[
                classes.inputWrapper,
                error ? classes.onError : "",
              ].join(" ")}
            >
              <label className={classes.formControlLabel} htmlFor="username">
                Usuário
              </label>
              <input
                className={classes.loginInput}
                type="text"
                name="username"
                placeholder="Informe o nome do usuário para acessar"
              />
              <div className={classes.icon}>
                <img
                  width="14px"
                  height="15px"
                  src="https://storage.googleapis.com/armazenamento-imagens/assets/username-new-blue.svg"
                  alt=""
                />
              </div>
            </div>
            <div
              className={[
                classes.inputWrapper,
                error ? classes.onError : "",
              ].join(" ")}
            >
              <label className={classes.formControlLabel} htmlFor="password">
                Senha
              </label>
              <input
                className={classes.loginPasswordInput}
                type={passwordVisibility ? "text" : "password"}
                name="password"
                placeholder="Informe sua senha"
              />
              <div
                className={classes.icon}
                data-testid={"show-password-icon"}
                onClick={handleShowPassword}
              >
                {passwordVisibility ? (
                  <img
                    data-testid={"visibilityOn"}
                    width="16px"
                    height="17px"
                    src="https://storage.googleapis.com/armazenamento-imagens/assets/visibility-on-new-blue.svg"
                    alt="visibilityOn"
                  />
                ) : (
                  <img
                    data-testid={"visibilityOff"}
                    width="16px"
                    height="17px"
                    src="https://storage.googleapis.com/armazenamento-imagens/assets/visibility-off-new-blue.svg"
                    alt="visibilityOff"
                  />
                )}
              </div>
            </div>

            <div className={classes.forgotPasswordWrapper}>
              <span
                className={classes.forgotPasswordLink}
                onClick={navigateToForgotPassword}
              >
                Esqueci a senha
              </span>
            </div>

            <button className={classes.loginButton} type="submit">
              Entrar
            </button>
          </form>
        </div>

        <div className={classes.bgOxWrapper}></div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  exibirCarregando: action.exibirCarregando,
  notifyError: action.notifyError,
};

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(LoginPage);
