import React from "react";
import { Grid, Button, Tooltip, withStyles } from "@material-ui/core";
import moment from "moment";
import { DatePicker } from "material-ui-pickers";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";
import ModalDataConciliacao from "./modalDataConciliacao";
import { withRouter } from "react-router-dom";

const DATA_INICIO_MS_ANIMAIS = "08/17/2021";

export class CategoriaCabecalho extends React.Component {
  constructor(props) {
    super(props);
    const dataAtual = Date.now();
    const dataInicioConciliacao = this.props.dataInicioConciliacao
      ? moment.utc(this.props.dataInicioConciliacao).format("MM/DD/YYYY")
      : null;
    this.state = {
      dataAtual: dataAtual,
      dataInicioConciliacao: dataInicioConciliacao,
      modalAberto: false,
      errorDataConciliacao: false,
    };
  }

  handleClose = async () => {
    this.setState({ modalAberto: false });
  };

  handleOpen = async () => {
    this.setState({ modalAberto: true });
  };

  handleErrorDataConciliacao = (date) => {
    this.setState({
      errorDataConciliacao: true,
      dataInicioConciliacao: date.isValid() ? date : null,
    });
  };

  shouldComponentUpdate(nextState) {
    const dataInicioDiferente = nextState.dataAtual !== this.state.dataAtual;
    const dataFimDiferente =
      nextState.dataInicioConciliacao !== this.state.dataInicioConciliacao;
    return dataInicioDiferente || dataFimDiferente;
  }

  setData(data) {
    const tempPeriodo = data.format("MM/DD/YYYY");
    this.setState({
      dataInicioConciliacao: tempPeriodo,
      errorDataConciliacao: false,
    });
  }

  onButtonClick = async () => {
    this.props.loading(true);
    try {
      var timezone = this.props.timezoneFazenda
        ? this.props.timezoneFazenda
        : 0;
      const dataParaSalvar = {
        DataInicioConciliacao: moment(this.state.dataInicioConciliacao)
          .utcOffset(timezone)
          .toDate(),
      };

      await this.props.salvaDataConciliacao(
        dataParaSalvar,
        this.props.idFazenda
      );
      this.props.loading(false);
      this.setState({ modalAberto: false });
    } catch (error) {
      console.error(error);
      this.props.loading(false);
    }
  };

  render() {
    const { classes } = this.props;
    const { dataInicioConciliacao, errorDataConciliacao } = this.state;
    const { pathname } = this.props.location;
    const erroMaxDateMessage = "A data não pode ser maior que a data atual";
    return (
      <Grid>
        {pathname !== "/configuracoes/cadastros" && (
          <>
            <Typography className={classes.titulo} gutterBottom>
              Insira a data de início da sua conciliação:
            </Typography>

            <div className={classes.dataInicial}>
              <DatePicker
                format="DD/MM/YYYY"
                minDate={moment(DATA_INICIO_MS_ANIMAIS)}
                minDateMessage={`A data inserida ultrapassa a data minima permitida de ${moment(
                  DATA_INICIO_MS_ANIMAIS
                ).format("DD/MM/YYYY")}`}
                maxDate={this.state.dataAtual}
                onError={this.handleErrorDataConciliacao}
                onlyCalendar
                keyboard
                label={
                  <div
                    style={{
                      display: "flex",
                      bottom: "8px",
                      position: "relative",
                    }}
                  >
                    <Typography style={{ fontSize: "17px" }}>
                      Data Inicial
                    </Typography>{" "}
                    <Tooltip
                      title={"Data do início de conciliação do rebanho"}
                      arrow
                      placement="right"
                    >
                      <InfoIcon
                        fontSize="inherit"
                        style={{
                          pointerEvents: "auto",
                          fontSize: "17px",
                          position: "relative",
                          top: "2px",
                          left: "8px",
                          color: "grey",
                        }}
                      />
                    </Tooltip>
                  </div>
                }
                placeholder="dd/mm/aaaa"
                disableFuture={true}
                invalidDateMessage="O Formato da data é inválido"
                maxDateMessage={erroMaxDateMessage}
                mask={(value) =>
                  value
                    ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
                    : []
                }
                animateYearScrolling={false}
                value={dataInicioConciliacao}
                onChange={(e) => this.setData(e)}
                InputLabelProps={{
                  style: { zIndex: 1, pointerEvents: "none" },
                }}
                style={{
                  width: 150,
                  marginRight: 16,
                }}
              />
              <Button
                id="botaoSalvarDataInicioConciliacao"
                variant="contained"
                color="primary"
                className={classes.botaoDataInicial}
                disabled={!dataInicioConciliacao || errorDataConciliacao}
                onClick={() => {
                  this.props.dataInicioConciliacao
                    ? this.handleOpen()
                    : this.onButtonClick();
                }}
              >
                {this.props.dataInicioConciliacao ? "Editar Data" : "Aplicar"}
              </Button>
            </div>
          </>
        )}
        <ModalDataConciliacao
          onConfirm={this.onButtonClick}
          modalAberto={this.state.modalAberto}
          handleClose={this.handleClose}
        />
      </Grid>
    );
  }
}

const styles = () => {
  return {
    botaoDataInicial: {
      height: "36px",
      borderRadius: "18px",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "1.25px",
      textAlign: "center",
      color: "#ffffff",
      bottom: "36px",
      minWidth: "140px",
      position: "unset!important",
      marginTop: "10px",
    },
    dataInicial: {
      display: "flex",
      height: 80,
    },
    textoPesagem: {
      width: "147px",
      height: "32px",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#eb384c",
    },
    titulo: {
      fontSize: "20px",
      fontWeight: "bold",
      fontStyle: "medium",
      fontStretch: "medium",
      lineHeight: "medium",
      letterSpacing: "medium",
      color: "#333333",
      marginTop: "40px",
      marginBottom: "20px",
    },
    areaSelectInput: {
      paddingBottom: "0.480em",
      marginLeft: "-8px",
      marginRight: "8px",
    },
    areaSecondDatePicker: { marginRight: "-3.5em", marginLeft: "3.5em" },
  };
};

export default withRouter(withStyles(styles)(CategoriaCabecalho));
