import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import * as action from "../../../redux/actions";
import { useForgotPassword } from "../hooks/useForgotPassword";

const ForgotPasswordPage = ({ match, classes, notifyError, notifySuccess }) => {
  const { origem } = match.params || {};
  const { onSubmit, navigateToLogin, error } = useForgotPassword({
    origem,
    notifyError,
    notifySuccess,
  });

  return (
    <div className={classes.container}>
      <div className={classes.loaderWrapper}>
        <div className={classes.loader}></div>
      </div>
      <div className={classes.loginPage}>
        <div className={classes.loginForm}>
          <header>
            <div className={classes.logoWrapper}>
              <img
                className={classes.logoWrapperImg}
                src="https://storage.googleapis.com/armazenamento-imagens/logo/logo-Farmtell-Views-TM-Cor.svg"
                alt=""
              />
            </div>

            <div className={classes.greetings}>
              <h1 className={classes.greetingWelcome}>Esqueceu sua senha?</h1>
              <p className={classes.greetingSubtitle}>
                Não se preocupe, basta informar o e-mail cadastrado no campo
                abaixo e você receberá instruções para redefinir a sua senha.
              </p>
            </div>
          </header>

          <form className={classes.form} onSubmit={onSubmit}>
            <div
              className={[
                classes.inputWrapper,
                error ? classes.onError : "",
              ].join(" ")}
            >
              <label className={classes.formControlLabel} htmlFor="username">
                E-mail
              </label>
              <input
                className={classes.loginInput}
                type="text"
                name="email"
                placeholder="Informe o e-mail cadastrado"
              />
              <div className={classes.icon}>
                <img
                  width="17px"
                  height="18px"
                  src="https://storage.googleapis.com/armazenamento-imagens/assets/email-new-blue.svg"
                  alt=""
                />
              </div>
            </div>

            <p className={classes.resetPasswordInfo}>
              Esqueceu seu login ou e-mail? Entre em contato com a nossa equipe:{" "}
              <a
                className={classes.formFooterA}
                href="mailto:suporte@prodap.com.br"
              >
                suporte@prodap.com.br
              </a>
            </p>

            <button className={classes.submitButton} type="submit">
              Enviar link de redefinição
            </button>
          </form>

          <div
            onClick={navigateToLogin}
            className={classes.navigateToLoginLink}
          >
            <img
              alt="arrow back"
              className={classes.arrowIcon}
              src="../images/arrow-back.svg"
            />
            <span>Voltar para o login</span>
          </div>
        </div>

        <div className={classes.bgOxWrapper}></div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  notifyError: action.notifyError,
  notifySuccess: action.notifySuccess,
};

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(ForgotPasswordPage);
